import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PortableText from "react-portable-text";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import NewsLetters from "../components/common/newsletter";
import Seo from "../components/common/SEO";

export default function PressRelease({ data: { sanityPressReleases } }) {
  return (
    <Layout>
      <Seo
        title={sanityPressReleases?.seo?.metaTitle}
        description={sanityPressReleases?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading title={sanityPressReleases?.title} />
            <div className="pt-10">
              <GatsbyImage
                image={sanityPressReleases?.thumbnail?.asset?.gatsbyImageData}
                alt={sanityPressReleases?.title}
              />
              {sanityPressReleases?._rawContent && (
                <div className="mt-12">
                  <PortableText
                    content={sanityPressReleases?._rawContent}
                    className="px-4 block-content max-w-none md:px-0"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-[50px]">
          <NewsLetters small={true} />
        </div>
      </div>
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    sanityPressReleases(slug: { current: { eq: $slug } }) {
      title
      excerpt
      slug {
        current
      }
      _rawContent
      thumbnail {
        asset {
          gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
